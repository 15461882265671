export const darkColors = {
  Neutral: "#161A1D",
  text: "#ffffff",
  colorBackgroundDisabled: "#bcd6f00a",
  colorBackgroundInput: "#22272b",
  colorBackgroundInputHovered: "#282e33",
  colorBackgroundInputPressed: "#22272b",
  colorBackgroundAccentBlueBolder: "#579dff",
  colorBackgroundAccentBlueBolderHovered: "#85b8ff",
  colorBackgroundAccentBlueBolderPressed: "#cce0ff",
  colorBackgroundAccentBlueSubtle: "#0055cc",
  colorBackgroundAccentBlueSubtleHovered: "#09326c",
  colorBackgroundAccentBlueSubtlePressed: "#082145",
  colorBackgroundAccentBlueSubtler: "#09326c",
  colorBackgroundAccentBlueSubtlerHovered: "#0055cc",
  colorBackgroundAccentBlueSubtlerPressed: "#0c66e4",
  colorBackgroundAccentBlueSubtlest: "#082145",
  colorBackgroundAccentBlueSubtlestHovered: "#09326c",
  colorBackgroundAccentBlueSubtlestPressed: "#0055cc",
  colorBackgroundAccentGrayBolder: "#8c9bab",
  colorBackgroundAccentGrayBolderHovered: "#9fadbc",
  colorBackgroundAccentGrayBolderPressed: "#b6c2cf",
  colorBackgroundAccentGraySubtle: "#596773",
  colorBackgroundAccentGraySubtleHovered: "#454f59",
  colorBackgroundAccentGraySubtlePressed: "#38414a",
  colorBackgroundAccentGraySubtler: "#454f59",
  colorBackgroundAccentGraySubtlerHovered: "#596773",
  colorBackgroundAccentGraySubtlerPressed: "#738496",
  colorBackgroundAccentGraySubtlest: "#2c333a",
  colorBackgroundAccentGraySubtlestHovered: "#38414a",
  colorBackgroundAccentGraySubtlestPressed: "#454f59",
  colorBackgroundAccentGreenBolder: "#4bce97",
  colorBackgroundAccentGreenBolderHovered: "#7ee2b8",
  colorBackgroundAccentGreenBolderPressed: "#baf3db",
  colorBackgroundAccentGreenSubtle: "#216e4e",
  colorBackgroundAccentGreenSubtleHovered: "#164b35",
  colorBackgroundAccentGreenSubtlePressed: "#143c2b",
  colorBackgroundAccentGreenSubtler: "#164b35",
  colorBackgroundAccentGreenSubtlerHovered: "#216e4e",
  colorBackgroundAccentGreenSubtlerPressed: "#1f845a",
  colorBackgroundAccentGreenSubtlest: "#143c2b",
  colorBackgroundAccentGreenSubtlestHovered: "#164b35",
  colorBackgroundAccentGreenSubtlestPressed: "#216e4e",
  colorBackgroundAccentLimeBolder: "#94c748",
  colorBackgroundAccentLimeBolderHovered: "#b3df72",
  colorBackgroundAccentLimeBolderPressed: "#d3f1a7",
  colorBackgroundAccentLimeSubtle: "#4c6b1f",
  colorBackgroundAccentLimeSubtleHovered: "#37471f",
  colorBackgroundAccentLimeSubtlePressed: "#2a3818",
  colorBackgroundAccentLimeSubtler: "#37471f",
  colorBackgroundAccentLimeSubtlerHovered: "#4c6b1f",
  colorBackgroundAccentLimeSubtlerPressed: "#5b7f24",
  colorBackgroundAccentLimeSubtlest: "#2a3818",
  colorBackgroundAccentLimeSubtlestHovered: "#37471f",
  colorBackgroundAccentLimeSubtlestPressed: "#4c6b1f",
  colorBackgroundAccentMagentaBolder: "#e774bb",
  colorBackgroundAccentMagentaBolderHovered: "#f797d2",
  colorBackgroundAccentMagentaBolderPressed: "#fdd0ec",
  colorBackgroundAccentMagentaSubtle: "#943d73",
  colorBackgroundAccentMagentaSubtleHovered: "#50253f",
  colorBackgroundAccentMagentaSubtlePressed: "#421f34",
  colorBackgroundAccentMagentaSubtler: "#50253f",
  colorBackgroundAccentMagentaSubtlerHovered: "#943d73",
  colorBackgroundAccentMagentaSubtlerPressed: "#ae4787",
  colorBackgroundAccentMagentaSubtlest: "#421f34",
  colorBackgroundAccentMagentaSubtlestHovered: "#50253f",
  colorBackgroundAccentMagentaSubtlestPressed: "#943d73",
  colorBackgroundAccentOrangeBolder: "#faa53d",
  colorBackgroundAccentOrangeBolderHovered: "#fec57b",
  colorBackgroundAccentOrangeBolderPressed: "#ffe2bd",
  colorBackgroundAccentOrangeSubtle: "#974f0c",
  colorBackgroundAccentOrangeSubtleHovered: "#5f3811",
  colorBackgroundAccentOrangeSubtlePressed: "#4a2b0f",
  colorBackgroundAccentOrangeSubtler: "#5f3811",
  colorBackgroundAccentOrangeSubtlerHovered: "#974f0c",
  colorBackgroundAccentOrangeSubtlerPressed: "#b65c02",
  colorBackgroundAccentOrangeSubtlest: "#4a2b0f",
  colorBackgroundAccentOrangeSubtlestHovered: "#5f3811",
  colorBackgroundAccentOrangeSubtlestPressed: "#974f0c",
  colorBackgroundAccentPurpleBolder: "#9f8fef",
  colorBackgroundAccentPurpleBolderHovered: "#b8acf6",
  colorBackgroundAccentPurpleBolderPressed: "#dfd8fd",
  colorBackgroundAccentPurpleSubtle: "#5e4db2",
  colorBackgroundAccentPurpleSubtleHovered: "#352c63",
  colorBackgroundAccentPurpleSubtlePressed: "#231c3f",
  colorBackgroundAccentPurpleSubtler: "#352c63",
  colorBackgroundAccentPurpleSubtlerHovered: "#5e4db2",
  colorBackgroundAccentPurpleSubtlerPressed: "#6e5dc6",
  colorBackgroundAccentPurpleSubtlest: "#231c3f",
  colorBackgroundAccentPurpleSubtlestHovered: "#352c63",
  colorBackgroundAccentPurpleSubtlestPressed: "#5e4db2",
  colorBackgroundAccentRedBolder: "#f87462",
  colorBackgroundAccentRedBolderHovered: "#ff9c8f",
  colorBackgroundAccentRedBolderPressed: "#ffd2cc",
  colorBackgroundAccentRedSubtle: "#ae2a19",
  colorBackgroundAccentRedSubtleHovered: "#601e16",
  colorBackgroundAccentRedSubtlePressed: "#4f1c16",
  colorBackgroundAccentRedSubtler: "#601e16",
  colorBackgroundAccentRedSubtlerHovered: "#ae2a19",
  colorBackgroundAccentRedSubtlerPressed: "#ca3521",
  colorBackgroundAccentRedSubtlest: "#4f1c16",
  colorBackgroundAccentRedSubtlestHovered: "#601e16",
  colorBackgroundAccentRedSubtlestPressed: "#ae2a19",
  colorBackgroundAccentTealBolder: "#60c6d2",
  colorBackgroundAccentTealBolderHovered: "#8bdbe5",
  colorBackgroundAccentTealBolderPressed: "#c1f0f5",
  colorBackgroundAccentTealSubtle: "#206b74",
  colorBackgroundAccentTealSubtleHovered: "#1d474c",
  colorBackgroundAccentTealSubtlePressed: "#15373b",
  colorBackgroundAccentTealSubtler: "#1d474c",
  colorBackgroundAccentTealSubtlerHovered: "#206b74",
  colorBackgroundAccentTealSubtlerPressed: "#1d7f8c",
  colorBackgroundAccentTealSubtlest: "#15373b",
  colorBackgroundAccentTealSubtlestHovered: "#1d474c",
  colorBackgroundAccentTealSubtlestPressed: "#206b74",
  colorBackgroundAccentYellowBolder: "#ffc52c",
  colorBackgroundAccentYellowBolderHovered: "#ffcf51",
  colorBackgroundAccentYellowBolderPressed: "#ffe399",
  colorBackgroundAccentYellowSubtle: "#d67d00",
  colorBackgroundAccentYellowSubtleHovered: "#6f4100",
  colorBackgroundAccentYellowSubtlePressed: "#533000",
  colorBackgroundAccentYellowSubtler: "#6f4100",
  colorBackgroundAccentYellowSubtlerHovered: "#d67d00",
  colorBackgroundAccentYellowSubtlerPressed: "#ff9602",
  colorBackgroundAccentYellowSubtlest: "#533000",
  colorBackgroundAccentYellowSubtlestHovered: "#6f4100",
  colorBackgroundAccentYellowSubtlestPressed: "#d67d00",
  colorBackgroundBrandPrimaryBold: "#FCB026",
  colorBackgroundBrandPrimaryBoldHovered: "#85b8ff",
  colorBackgroundBrandPrimaryBoldPressed: "#cce0ff",
  colorBackgroundBrandPrimaryBolder: "#ebf3ff",
  colorBackgroundBrandPrimaryBolderHovered: "#cce0ff",
  colorBackgroundBrandPrimaryBolderPressed: "#85b8ff",
  colorBackgroundBrandPrimarySubtlest: "#082145",
  colorBackgroundBrandPrimarySubtlestHovered: "#09326c",
  colorBackgroundBrandPrimarySubtlestPressed: "#0055cc",
  colorBackgroundBrandSecondaryBold: "#9f8fef",
  colorBackgroundBrandSecondaryBoldHovered: "#b8acf6",
  colorBackgroundBrandSecondaryBoldPressed: "#dfd8fd",
  colorBackgroundBrandSecondaryBolder: "#f3f0ff",
  colorBackgroundBrandSecondaryBolderHovered: "#dfd8fd",
  colorBackgroundBrandSecondaryBolderPressed: "#b8acf6",
  colorBackgroundBrandSecondarySubtlest: "#231c3f",
  colorBackgroundBrandSecondarySubtlestHovered: "#352c63",
  colorBackgroundBrandSecondarySubtlestPressed: "#5e4db2",
  colorBackgroundDangerBold: "#E3021B",
  colorBackgroundDangerBoldHovered: "#ff9c8f",
  colorBackgroundDangerBoldPressed: "#ffd2cc",
  colorBackgroundDangerDefault: "#4f1c16",
  colorBackgroundDangerHovered: "#601e16",
  colorBackgroundDangerPressed: "#ae2a19",
  colorBackgroundDiscoveryBold: "#9f8fef",
  colorBackgroundDiscoveryBoldHovered: "#b8acf6",
  colorBackgroundDiscoveryBoldPressed: "#dfd8fd",
  colorBackgroundDiscoveryDefault: "#231c3f",
  colorBackgroundDiscoveryHovered: "#352c63",
  colorBackgroundDiscoveryPressed: "#5e4db2",
  colorBackgroundInformationBold: "#579dff",
  colorBackgroundInformationBoldHovered: "#85b8ff",
  colorBackgroundInformationBoldPressed: "#cce0ff",
  colorBackgroundInformationDefault: "#082145",
  colorBackgroundInformationHovered: "#09326c",
  colorBackgroundInformationPressed: "#0055cc",
  colorBackgroundNeutralBolder: "#9fadbc",
  colorBackgroundNeutralBolderHovered: "#b6c2cf",
  colorBackgroundNeutralBolderPressed: "#c7d1db",
  colorBackgroundNeutralBoldest: "#f7f8f9",
  colorBackgroundNeutralBoldestHovered: "#dcdfe4",
  colorBackgroundNeutralBoldestPressed: "#b3b9c4",
  colorBackgroundNeutralDefault: "rgba(9, 30, 66, 0.06))",
  colorBackgroundNeutralHovered: "#a6c5e229",
  colorBackgroundNeutralPressed: "#bfdbf847",
  colorBackgroundNeutralSubtle: "#ffffff00",
  colorBackgroundNeutralSubtleHovered: "#a1bdd914",
  colorBackgroundNeutralSubtlePressed: "#a6c5e229",
  colorBackgroundSuccessBold: "#4bce97",
  colorBackgroundSuccessBoldHovered: "#7ee2b8",
  colorBackgroundSuccessBoldPressed: "#baf3db",
  colorBackgroundSuccessDefault: "#143c2b",
  colorBackgroundSuccessHovered: "#164b35",
  colorBackgroundSuccessPressed: "#216e4e",
  colorBackgroundSurfaceSurface: "#161a1d",
  colorBackgroundSurfaceSurfaceHovered: "#22272b",
  colorBackgroundSurfaceSurfaceOverlay: "#282e33",
  colorBackgroundSurfaceSurfaceOverlayHovered: "#2c333a",
  colorBackgroundSurfaceSurfaceOverlayPressed: "#38414a",
  colorBackgroundSurfaceSurfacePressed: "#2c333a",
  colorBackgroundSurfaceSurfaceRaised: "#22272b",
  colorBackgroundSurfaceSurfaceRaisedHovered: "#282e33",
  colorBackgroundSurfaceSurfaceRaisedPressed: "#2c333a",
  colorBackgroundSurfaceSurfaceReversed: "#1d2125",
  colorBackgroundSurfaceSurfaceReversedHovered: "#22272b",
  colorBackgroundSurfaceSurfaceReversedPressed: "#2c333a",
  colorBackgroundWarningBold: "#ffc52c",
  colorBackgroundWarningBoldHovered: "#ffcf51",
  colorBackgroundWarningBoldPressed: "#ffe399",
  colorBackgroundWarningDefault: "#533000",
  colorBackgroundWarningHovered: "#6f4100",
  colorBackgroundWarningPressed: "#d67d00",
  colorBorderBrand: "#579dff",
  colorBorderBrandSecondary: "#9f8fef",
  colorBorderDanger: "#ef5c48",
  colorBorderDisabled: "#a1bdd914",
  colorBorderDiscovery: "#8f7ee7",
  colorBorderFocused: "#85b8ff",
  colorBorderHovered: "#bfdbf847",
  colorBorderInformation: "#388bff",
  colorBorderInverse: "#1d2125",
  colorBorderInvisible: "#ffffff00",
  colorBorderPrimary: "#a6c5e229",
  colorBorderReverse: "#ffffff",
  colorBorderSuccess: "#2abb7f",
  colorBorderWarning: "#ffb506",
  colorBorderWarningInverse: "#1d2125",
  colorBorderAccentBlue: "#388bff",
  colorBorderAccentGray: "#738496",
  colorBorderAccentGreen: "#2abb7f",
  colorBorderAccentLime: "#82b536",
  colorBorderAccentMagenta: "#da62ac",
  colorBorderAccentOrange: "#f18d13",
  colorBorderAccentPurple: "#8f7ee7",
  colorBorderAccentRed: "#ef5c48",
  colorBorderAccentTeal: "#37b4c3",
  colorBorderAccentYellow: "#ffb506",
  colorBorderBrandPrimaryBold: "#579dff",
  colorBorderBrandPrimaryBoldHovered: "#85b8ff",
  colorBorderBrandPrimaryBoldPressed: "#cce0ff",
  colorBorderBrandSecondaryBold: "#9f8fef",
  colorBorderBrandSecondaryBoldHovered: "#b8acf6",
  colorBorderBrandSecondaryBoldPressed: "#dfd8fd",
  colorBorderDangerBold: "#f87462",
  colorBorderDangerBoldHovered: "#ff9c8f",
  colorBorderDangerBoldPressed: "#ffd2cc",
  colorBorderDiscoveryBold: "#9f8fef",
  colorBorderDiscoveryBoldHovered: "#b8acf6",
  colorBorderDiscoveryBoldPressed: "#dfd8fd",
  colorBorderInformationBold: "#579dff",
  colorBorderInformationBoldHovered: "#85b8ff",
  colorBorderInformationBoldPressed: "#cce0ff",
  colorBorderLimeBolder: "#94c748",
  colorBorderLimeBolderHovered: "#b3df72",
  colorBorderLimeBolderPressed: "#d3f1a7",
  colorBorderNeutralBolder: "#738496",
  colorBorderNeutralBolderHovered: "#b6c2cf",
  colorBorderNeutralBolderPressed: "#b6c2cf",
  colorBorderSuccessBold: "#4bce97",
  colorBorderSuccessBoldHovered: "#7ee2b8",
  colorBorderSuccessBoldPressed: "#baf3db",
  colorBorderWarningBold: "#ffc52c",
  colorBorderWarningBoldHovered: "#ffcf51",
  colorBorderWarningBoldPressed: "#ffe399",
  colorLinkDefault: "#C7D1DB",
  colorLinkPressed: "#44546f",
  colorLinkVisited: "#626f86",
  colorLinkBrandedDefault: "#579dff",
  colorLinkBrandedPressed: "#85b8ff",
  colorLinkBrandedVisited: "#b8acf6",
  colorShapeBrand: "#579dff",
  colorShapeBrandSecondary: "#9f8fef",
  colorShapeDanger: "#ef5c48",
  colorShapeDisabled: "#bfdbf847",
  colorShapeDiscovery: "#8f7ee7",
  colorShapeInformation: "#388bff",
  colorShapeInverse: "#1d2125",
  colorShapePrimary: "#c7d1db",
  colorShapeReverse: "#ffffff",
  colorShapeSuccess: "#2abb7f",
  colorShapeWarning: "#ffb506",
  colorShapeWarningInverse: "#1d2125",
  colorShapeAccentBlue: "#388bff",
  colorShapeAccentGray: "#738496",
  colorShapeAccentGreen: "#2abb7f",
  colorShapeAccentLime: "#82b536",
  colorShapeAccentMagenta: "#da62ac",
  colorShapeAccentOrange: "#f18d13",
  colorShapeAccentPurple: "#8f7ee7",
  colorShapeAccentRed: "#ef5c48",
  colorShapeAccentTeal: "#37b4c3",
  colorShapeAccentYellow: "#ffb506",
  colorShapeNeutralBolder: "#8c9bab",
  colorShapeNeutralBolderHovered: "#b6c2cf",
  colorShapeNeutralBolderPressed: "#dee4ea",
  colorTextBrand: "#579dff",
  colorTextBrandSecondary: "#9f8fef",
  colorTextDanger: "#ff9c8f",
  colorTextDisabled: "#bfdbf847",
  colorTextDiscovery: "#b8acf6",
  colorTextInformation: "#85b8ff",
  colorTextInverse: "#1d2125",
  colorTextPrimary: "#c7d1db",
  colorTextSubtle: "#9fadbc",
  colorTextSubtlest: "#8c9bab",
  colorTextSuccess: "#7ee2b8",
  colorTextWarning: "#ffcf51",
  colorTextWarningInverse: "#1d2125",
  colorTextStacked: "#ffffff",
  colorTextAccentBlue: "#85b8ff",
  colorTextAccentBlueBolder: "#cce0ff",
  colorTextAccentGreen: "#7ee2b8",
  colorTextAccentGreenBolder: "#baf3db",
  colorTextAccentLime: "#b3df72",
  colorTextAccentLimeBolder: "#d3f1a7",
  colorTextAccentMagenta: "#f797d2",
  colorTextAccentMagentaBolder: "#fdd0ec",
  colorTextAccentMoonstone: "#8bdbe5",
  colorTextAccentMoonstoneBolder: "#c1f0f5",
  colorTextAccentOrange: "#fec57b",
  colorTextAccentOrangeBolder: "#ffe2bd",
  colorTextAccentPurple: "#b8acf6",
  colorTextAccentPurpleBolder: "#dfd8fd",
  colorTextAccentRed: "#ff9c8f",
  colorTextAccentRedBolder: "#ffd2cc",
  colorTextAccentYellow: "#ffcf51",
  colorTextAccentYellowBolder: "#ffe399",
};

export const buttonsColors = {
  buttonBg: "#000",
  buttonText: "#fff",
};
