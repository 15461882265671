import { globalStyles } from "consts/spaces";
import styled from "styled-components";

export const CallRequest = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  z-index: 12;

  .layout {
    opacity: 0.8;
    background: var(--Color-Background-Neutral-Boldest, #1d2125);
    width: 100%;
    height: 100dvh;
  }

  .relative {
    position: relative;

    select {
      position: absolute;
      left: 37px;
      top: 23px;
      z-index: 2;
      font-size: 1rem;
      color: #172b4d;
      border: 0;
      height: 54px;
      background: transparent;
      width: 64px;
      outline: none;

      option {
        font-size: 18px;
      }
    }

    div:before {
      content: "";
      display: inline-block;
      background: rgba(9, 30, 66, 0.31);
      height: 24px;
      width: 1px;
      position: absolute;
      left: 87px;
      top: 15px;
    }

    input {
      padding-left: 100px;
    }
  }

  .category {
    cursor: pointer;
    color: ${(props) => props.theme.colorLinkDefault};
    font-weight: bold;
  }

  .sub-category-conainer {
    margin-bottom: 32px;
    gap: 4px;

    svg {
      transform: rotate(180deg);
    }
  }

  .sub-category {
    cursor: pointer;
    color: ${(props) => props.theme.colorLinkDefault};

    &.active {
      font-weight: bold;
    }
  }

  svg {
    cursor: pointer;
  }

  .modal {
    background: #fff;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;

    &-body {
      border-top: 1px solid var(--Color-Border-Primary, rgba(9, 30, 66, 0.14));
      border-bottom: 1px solid
        var(--Color-Border-Primary, rgba(9, 30, 66, 0.14));
      max-height: 77vh;
      overflow: auto;

      h3 {
        font-size: 20px;
        margin-bottom: 1rem;
        color: ${(props) => props.theme.colorTextPrimary};
      }

      @media (orientation: landscape) {
        max-height: 30vw;
      }
    }

    &-header {
      position: relative;
      color: ${(props) => props.theme.colorTextPrimary};
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;

      svg {
        cursor: pointer;
      }

      .closeIcon {
        position: absolute;
        right: ${globalStyles.spaces[300]};
        top: ${globalStyles.spaces[150]};
      }
    }

    @media (min-width: ${globalStyles.breackPoints.tablet}) {
      width: 96%;
      max-width: 660px;
      top: 50%;
      left: 50%;
      bottom: auto;
      transform: translate(-50%, -50%);
    }
  }
`;
