import { globalStyles } from "consts/spaces";
import { currentLangUrl } from "helpers";
import { FC, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { IHomeProject } from "types/home";

const HomeOfferContainer = styled.div<{ last?: boolean }>`
  position: relative;
  margin: 0 16px;
  visibility: ${(props) => (props.last ? "hidden" : "visible")};


  div {
    width: 100%;
    object-fit: cover;
    display: block;
    height: 340px;
    border-radius: 16px;
    background-size: cover !important;
  }

  img {
    // min-height: 284px;
    height: 284px;
    width: 100%;
    object-fit: cover;
  }

  h3 {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    color: ${(props) => props.theme.colorTextPrimary};
    margin: 16px 0 8px 0;

    overflow: hidden;
    display: -webkit-box;   
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 100%;
  }

  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: #44546F;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;   
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin: 0;
     width: 100%;
  }

  .cover {
    opacity: 1;
    position: absolute;
    left: 0;
    top: 0;
    height: 284px;
    rgba(211, 211, 211, 0.5);
  }

  &:hover {
    .cover {
      opacity: 0.9;
    }
  }
`;

export const SinleOffer = styled.div`
  img {
    width: 100%;
    height: 336px;
    // min-height: 336px;
    object-fit: cover;
  }

  h3 {
    margin: 16px 0 8px 0;
    font-size: 14px;
    font-weight: bold;
    color: #172b4d;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  p {
    margin: 0;
    font-size: 16px;
    color: #44546f;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  @media (max-width: ${globalStyles.breackPoints.mobile}) {
    img {
      height: 216px;
    }
  }
`;

const HomeSingleOffer: FC<IHomeProject> = (props) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [imgWidth, setImgWidth] = useState(0);

  useEffect(() => {
    const updateWidth = () => {
      if (imgRef.current) {
        setImgWidth(imgRef?.current?.offsetWidth);
      }
    };

    updateWidth();

    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, [imgRef?.current?.offsetWidth]);

  return (
    <HomeOfferContainer last={props?.last}>
      <Link to={currentLangUrl() + "/offers/" + props.slug}>
        <div className="cover" style={{ height: imgWidth / 1.33 }} />
        <img
          ref={imgRef}
          src={props.img}
          alt={props.title}
          style={{ height: imgWidth / 1.33 }}
        />

        <h3>{props.title}</h3>
        <p title={props.short_description}>{props.short_description}</p>
      </Link>
    </HomeOfferContainer>
  );
};

export default HomeSingleOffer;
