import { globalStyles } from "consts/spaces";
import styled from "styled-components";

export const FooterContainer = styled.footer`
  background-color: ${(props) => props.theme.colorBackgroundInputHovered};
`;

export const FooterCopyRight = styled.div`
  @media (max-width: ${globalStyles.breackPoints.mobile}) {
    text-align: left;
  }
`;

export const FooterDescription = styled.p`
  overflow: hidden;

  div {
    display: inline;
  }
  a {
    color: ${(props) => props.theme.colorPrimaryYellow};

    &:hover {
      color: ${(props) => props.theme.colorPrimaryYellow}; // to do
      text-decoration: underline;
    }
  }
`;

export const FooterBottomMenu = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colorBorderPrimary};
  border-top: 1px solid ${(props) => props.theme.colorBorderPrimary};
  flex-wrap: wrap;

  a {
    color: ${(props) => props.theme.colorLinkDefault};
    text-wrap: nowrap;

    &:hover {
      color: ${(props) => props.theme.colorLinkDefault};
      text-decoration: underline;
    }
  }

  @media (max-width: ${globalStyles.breackPoints.mobile}) {
    display: block;

    a {
      padding: 0 ${globalStyles.spaces[300]} ${globalStyles.spaces[300]} 0;
      display: block;
      text-wrap: wrap;
    }
  }
`;

export const FooterMenu = styled.div`
  border-top: 1px solid ${(props) => props.theme.colorBorderPrimary};

  .logo {
    border-radius: 8px;
  }

  @media (max-width: ${globalStyles.breackPoints.tablet}) {
    .socials {
      margin-bottom: ${globalStyles.spaces[500]};
    }

    h2 {
      padding-top: ${globalStyles.spaces[500]};
    }
  }
`;

export const FooterMenuLink = styled.div`
  h4 {
    color: ${(props) => props.theme.colorTextPrimary};
    margin-bottom: ${globalStyles.spaces[200]};
    margin-top: 0;
    font-size: ${globalStyles.fonts.b20};
  }

  img {
    max-height: 70px;
  }

  a {
    display: block;
    margin-bottom: ${globalStyles.spaces[200]};
    color: ${(props) => props.theme.colorLinkDefault};

    &:hover {
      color: ${(props) => props.theme.colorLinkDefault};
      text-decoration: underline;
    }
  }

  h2 {
    color: ${(props) => props.theme.colorTextPrimary};
    margin-bottom: ${globalStyles.spaces[200]};
    margin-top: 0;
  }

  .mr-auto {
    @media (max-width: ${globalStyles.breackPoints.md}) {
      margin-right: auto;
    }
  }

  p {
    color: ${(props) => props.theme.colorTextSubtlest};
    font-size: ${globalStyles.fonts.p14};
    max-width: 85%;
    margin: 0 0 ${globalStyles.spaces[150]} 0;

    @media (max-width: ${globalStyles.breackPoints.md}) {
      max-width: 100%;
      margin: 0 0 ${globalStyles.spaces[200]} 0;
    }
  }
`;
