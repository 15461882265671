import { FC } from "react";
import { Parallax } from "react-parallax";
import styled from "styled-components";
import { globalStyles } from "consts/spaces";
import useDeviceType from "helpers";
import { IHomeCover } from "types/home";

interface IProps {
  cover: IHomeCover;
}

const ParalaxContainer = styled.div`
  position: relative;

  .overlow {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 2;
    height: 100%;
    background: #00000099;
  }

  .text {
    position: absolute;
    z-index: 3;
    left: 10.5rem;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    max-width: 35%;

    h4 {
      margin-top: 0;
      font-size: ${globalStyles.fonts.large};
    }
  }

  .react-parallax-bgimage {
    background-position: top center;
    max-height: 100dvw;
  }

  .content {
    height: 550px;
  }

  @media (max-width: ${globalStyles.breackPoints.md}) {
    .content {
      height: 494px;
    }

    .text {
      max-width: 60%;
      left: 32px;

      h4 {
        font-size: ${globalStyles.fonts.display};
      }
    }
  }

  @media (max-width: ${globalStyles.breackPoints.tablet}) {
    .content {
      height: 366px;
    }

    .text {
      max-width: 90%;

      h4 {
        font-size: ${globalStyles.fonts.display};
      }
    }
  }

  @media (max-width: ${globalStyles.breackPoints.mobile}) {
    .content {
      height: 284px;
    }

    .text {
      max-width: 99%;
      left: 16px;
      bottom: 20px;

      h4 {
        font-size: ${globalStyles.fonts.h1};
      }
    }
  }
`;

const HomeBigSection: FC<IProps> = ({ cover }) => {
  const deviceType = useDeviceType();

  return (
    <ParalaxContainer className="paralax-container">
      <div className="overlow" />
      <Parallax
        bgImage={cover.media.url}
        bgImageAlt="the cat"
        strength={deviceType === "mobile" ? -40 : -200}
      >
        <div className="text">
          <h4 className="mb-200">{cover.title}</h4>
          <div>{cover.description}</div>
        </div>
        <div className="content" />
      </Parallax>
    </ParalaxContainer>
  );
};

export default HomeBigSection;
