/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "i18next";
import { langs } from "locales/languages";
import { ReactComponent as CloseIcon } from "assets/icons/menuClose.svg";
import CarretLeft from "assets/icons/CaretLeft.svg";
import { Button } from "components/styled/Button";
import { MobileMenuContainer } from "./styled";
import { ICategories } from "types/project";
import { useQuery, currentLangUrl } from "helpers";

interface IProps {
  open: boolean;
  categories: ICategories[];
  close: () => void;
}

const menuItems = [
  // { title: "secondaryEquipment", path: "/used" },
  { title: "offers", path: "/offers" },
  { title: "aboutUs", path: "/about" },
  { title: "blog", path: "/blog" },
  { title: "vlog", path: "/vlog" },
  { title: "contact", path: "/contact" },
];

const MobileMenu: FC<IProps> = ({ open, categories, close }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let query = useQuery();
  const redirect = (url: string) => {
    navigate(url);
    close();
  };

  const [currentLang, setCurrentLang] = useState("ka");
  const changeLang = (lang: string) => {
    setCurrentLang(lang);
    changeLanguage(lang);
  };

  const [openCategories, setOpenCategories] = useState(false);
  const [activeCategory, setAvtiveCategory] = useState("");

  useEffect(() => {
    if (query.get("type")) {
      setAvtiveCategory(query.get("type") || "");
    }
  }, [query]);

  return (
    <MobileMenuContainer open={open}>
      <div className="display-flex align-items-center justify-space-between pr-200 pl-200 header">
        {openCategories && (
          <img
            loading="lazy"
            src={CarretLeft}
            alt="left"
            onClick={() => setOpenCategories(false)}
          />
        )}

        <h2>{openCategories ? t("modelRange") : t("menu")}</h2>

        <CloseIcon onClick={() => close()} />
      </div>

      <div className="content">
        <div className="align-items-flex-start display-flex flex-column gap-100 pt-150 pb-300 header-menu-container">
          {openCategories ? (
            <>
              {categories.map((item) => (
                <Button
                  key={item.id}
                  onClick={() =>
                    redirect(`${currentLangUrl()}/products/${item.slug}`)
                  }
                  variant="base"
                  size="lg"
                  className={activeCategory === item.slug ? "active" : ""}
                >
                  {item.title}
                </Button>
              ))}
            </>
          ) : (
            <>
              <div
                className="display-flex align-items-center justify-space-between pr-200 w-100"
                onClick={() => setOpenCategories(true)}
              >
                <Button variant="base" size="lg">
                  {t("categories")}
                </Button>

                <svg
                  width="19"
                  height="18"
                  viewBox="0 0 19 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="CaretRight">
                    <path
                      id="Vector"
                      d="M13.2733 9.39792L7.64828 15.0229C7.59602 15.0752 7.53398 15.1166 7.46569 15.1449C7.39741 15.1732 7.32422 15.1878 7.25031 15.1878C7.1764 15.1878 7.10322 15.1732 7.03493 15.1449C6.96665 15.1166 6.90461 15.0752 6.85234 15.0229C6.80008 14.9707 6.75863 14.9086 6.73034 14.8403C6.70206 14.772 6.6875 14.6989 6.6875 14.6249C6.6875 14.551 6.70206 14.4779 6.73034 14.4096C6.75863 14.3413 6.80008 14.2792 6.85234 14.227L12.0801 8.99995L6.85234 3.77292C6.7468 3.66737 6.6875 3.52421 6.6875 3.37495C6.6875 3.22568 6.7468 3.08253 6.85234 2.97698C6.95789 2.87143 7.10105 2.81213 7.25031 2.81213C7.39958 2.81213 7.54273 2.87143 7.64828 2.97698L13.2733 8.60198C13.3256 8.65422 13.3671 8.71626 13.3954 8.78454C13.4237 8.85283 13.4383 8.92603 13.4383 8.99995C13.4383 9.07387 13.4237 9.14706 13.3954 9.21535C13.3671 9.28364 13.3256 9.34567 13.2733 9.39792Z"
                      fill="#44546F"
                    />
                  </g>
                </svg>
              </div>
              {menuItems.map((item) => (
                <Button
                  onClick={() => {
                    navigate(currentLangUrl() + item.path);
                    close();
                  }}
                  variant="base"
                  size="lg"
                  key={item.path}
                >
                  {t(item.title)}
                </Button>
              ))}
            </>
          )}
        </div>

        {!openCategories && (
          <div className="display-flex flex-column mt-400 mb-800 gap-150 pl-200 lang">
            {langs.map((item) => (
              <a
                key={item.key}
                className={currentLang === item.key ? "active" : ""}
                onClick={(e) => {
                  e.preventDefault();
                  changeLang(item.key);
                }}
                href="#"
              >
                {item.name}
              </a>
            ))}
          </div>
        )}
      </div>
    </MobileMenuContainer>
  );
};

export default MobileMenu;
