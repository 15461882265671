/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { VideoSliderContainer } from "./styled";
import CloseIcon from "../../../assets/icons/close.svg";
import Left from "../../../assets/icons/CaretRightWhite.svg";
import { FC, useEffect, useState } from "react";
import YouTube from "react-youtube";

interface IProps {
  open: boolean;
  onClose: () => void;
  imgs: string[];
  index: number;
}

const VideoSlider: FC<IProps> = ({ open, onClose, imgs, index }) => {
  const [active, setActive] = useState(index ? index : 0);

  useEffect(() => {
    setActive(index);
  }, [index]);

  const changeIndex = (prev?: boolean) => {
    if (prev) {
      setActive((prevActive) =>
        prevActive === 0 ? imgs.length - 1 : prevActive - 1
      );
    } else {
      setActive((prevActive) =>
        prevActive === imgs.length - 1 ? 0 : prevActive + 1
      );
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: { key: string }) => {
      if (event.key === "ArrowLeft") {
        changeIndex(true);
      } else if (event.key === "ArrowRight") {
        changeIndex();
      } else if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (active >= imgs.length) {
      setActive(0);
    }
    if (active < 0) {
      setActive(imgs.length);
    }
  }, [active]);

  const vw = screen.width < 992 ? screen.width * 0.8 : screen.width * 0.7;
  const vh = screen.height < 992 ? screen.height * 0.4 : screen.height * 0.6;
  const opts = {
    height: vh,
    width: vw,
    playerVars: {
      controls: 0,
      loop: 1,
      rel: 0,
      autoplay: 1,
    },
  };

  const getYouTubeVideoId = (url: string): string => {
    const regExp: RegExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match: RegExpMatchArray | null = url.match(regExp);

    if (match && match[2].length === 11) {
      return match[2];
    } else {
      return "";
    }
  };

  return (
    <VideoSliderContainer className={open ? "active" : ""}>
      <div onClick={() => onClose()} className="bg"></div>
      <img onClick={() => onClose()} src={CloseIcon} alt="close" />
      {open && (
        <div className="img-container">
          {imgs[active] && imgs[active].includes("youtube") ? (
            <YouTube opts={opts} videoId={getYouTubeVideoId(imgs[active])} />
          ) : (
            <video width={vw} height={vh} controls autoPlay key={imgs[active]}>
              {imgs[active] && <source src={imgs[active]} type="video/mp4" />}
            </video>
          )}
        </div>
      )}
      <div className="arrow">
        <img
          onClick={() => {
            changeIndex();
          }}
          className="right"
          src={Left}
          alt="left"
        />
        <img
          onClick={() => {
            changeIndex(true);
          }}
          className="left"
          src={Left}
          alt="right"
        />
      </div>
    </VideoSliderContainer>
  );
};

export default VideoSlider;
