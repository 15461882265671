import { FC, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as CaretDown } from "assets/icons/CaretDown.svg";
import { Button } from "components/styled/Button";
import { HeaderMenuContainer, MenuDropdown } from "./styled";
import { ICategories } from "types/project";
import { useQuery, currentLangUrl } from "helpers";

interface IProps {
  onClick: () => void;
  dark: boolean;
  path: string;
  categories: ICategories[];
}

const HeaderMenu: FC<IProps> = ({ path, categories }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  let query = useQuery();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeCategory, setAvtiveCategory] = useState("");

  useEffect(() => {
    if (query.get("type")) {
      setAvtiveCategory(query.get("type") || "");
    } else {
      setAvtiveCategory("");
    }
  }, [query]);

  return (
    <HeaderMenuContainer className="hide-md">
      <div
        ref={dropdownRef}
        className={`dropdown-container ${showDropdown ? "hover" : ""}`}
        onMouseEnter={() => setShowDropdown(true)}
        onMouseLeave={() => setShowDropdown(false)}
      >
        <Button
          className={`${path === "/products" ? "active" : ""} categories`}
          onClick={() => navigate(`${currentLangUrl()}/products`)}
          variant="light"
          size="lg"
        >
          {t("categories")} <CaretDown />
        </Button>

        <MenuDropdown className="menu gap-100">
          {categories.map((item) => (
            <li
              key={item.id}
              onClick={() => setShowDropdown(false)}
              className={activeCategory === item.slug ? "active" : ""}
            >
              <Link to={`${currentLangUrl()}/products/${item.slug}`}>
                {item.title}
              </Link>
            </li>
          ))}
        </MenuDropdown>
      </div>

      {/* to do with array map */}
      {/* <Button
        className={`${path === "/used" ? "active" : ""}`}
        onClick={() => navigate("/used")}
        variant="light"
        size="lg"
      >
        {t("secondaryEquipment")}
      </Button> */}
      <Button
        className={`${path === "/offers" ? "active" : ""}`}
        onClick={() => navigate(currentLangUrl() + "/offers")}
        variant="light"
        size="lg"
      >
        {t("offers")}
      </Button>
      <Button
        className={`${path === "/about" ? "active" : ""}`}
        onClick={() => navigate(currentLangUrl() + "/about")}
        variant="light"
        size="lg"
      >
        {t("aboutUs")}
      </Button>
      <Button
        className={`${path === "/blog" ? "active" : ""}`}
        onClick={() => navigate(currentLangUrl() + "/blog")}
        variant="light"
        size="lg"
      >
        {t("blog")}
      </Button>
      <Button
        className={`${path === "/vlog" ? "active" : ""}`}
        onClick={() => navigate(currentLangUrl() + "/vlog")}
        variant="light"
        size="lg"
      >
        {t("vlog")}
      </Button>
      <Button
        className={`${path === "/contact" ? "active" : ""}`}
        onClick={() => navigate(currentLangUrl() + "/contact")}
        variant="light"
        size="lg"
      >
        {t("contact")}
      </Button>
    </HeaderMenuContainer>
  );
};

export default HeaderMenu;
