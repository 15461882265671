import Header from "./components/layout/Header";
import GlobalStyle from "./components/layout/GlobalStyles";
import { lightTheme } from "consts/theme";
import React, { useEffect, useState } from "react";
import Routes from "routes";
import { ThemeProvider } from "styled-components";
import "./assets/scss/index.scss";
import Footer from "./components/layout/Footer";
import ScrollToTop from "services/hooks/scroltop";
import { API } from "services/API/API";
import { ICategories } from "types/project";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const App: React.FC = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const [isDarkMode, setIsDarkMode] = useState<boolean>(false);
  const [lang, setLang] = useState<string>("en");
  const [categories, setCategories] = useState<ICategories[]>([]);
  const [footerCategories, setFooterCategories] = useState<ICategories[]>([]);
  const language = i18n.language;

  const toggleTheme = () => {
    setIsDarkMode((prevMode: boolean) => !prevMode);
  };

  useEffect(() => {
    API.projects.getCategories().then((res) => {
      const cats: ICategories[] = [];
      setCategories(res.data);

      res.data.forEach((item: ICategories) => {
        if (item.show_in_footer) {
          cats.push(item);
        }

        if (item?.children?.length) {
          item.children.forEach((child) => {
            if (child.show_in_footer) {
              cats.push(child);
            }
          });
        }
      });

      setFooterCategories(cats);
    });
  }, [language]);

  const makehreflang = () => {
    const currentUlr = location.pathname;
    const mainLink = "https://jcb.ge";

    if (currentUlr === "/") {
      return mainLink + "/en";
    } else if (currentUlr === "/en") {
      return mainLink;
    } else if (!currentUlr.includes("en")) {
      return mainLink + "/en" + currentUlr + location?.search;
    } else {
      return mainLink + currentUlr.replace("en/", "") + location?.search;
    }
  };

  useEffect(() => {
    const linkAlternate = document.createElement("link");
    linkAlternate.rel = "alternate";
    linkAlternate.href = makehreflang();
    linkAlternate.hreflang = i18n.language === "en" ? "ka" : "en";
    document.head.appendChild(linkAlternate);

    return () => {
      document.head.removeChild(linkAlternate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, location]);

  return (
    <ThemeProvider theme={lightTheme}>
      <Header
        dark={isDarkMode}
        lang={lang}
        setLang={setLang}
        toggleTheme={toggleTheme}
        categories={categories}
      />
      <ScrollToTop />
      <GlobalStyle />
      <Routes lang={lang} />
      <Footer categories={footerCategories} />
    </ThemeProvider>
  );
};

export default App;
