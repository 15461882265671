import { globalStyles } from "consts/spaces";
import styled from "styled-components";

export const ProjectsContainer = styled.div`
  h3 {
    color: #000;
  }

  @media (max-width: ${globalStyles.breackPoints.md}) {
    .title {
      text-align: center;
    }
  }
`;

export const SingleProjectContainer = styled.div`
  & > div > p,
  & > p {
    margin: ${globalStyles.spaces[200]} 0;
    color: ${(props) => props.theme.colorTextPrimary};
    font-weight: 400;
  }

  .description p {
    margin: 0;
  }

  .button {
    border-radius: var(--Input-Radius-100, 0px);
    border: 1px solid var(--Color-Border-Primary, rgba(9, 30, 66, 0.14));
    background: var(--Color-Background-Neutral-Subtle, rgba(255, 255, 255, 0));
    padding: ${globalStyles.spaces[150]} ${globalStyles.spaces[200]};

    span {
      cursor: pointer;

      a:hover {
        color: ${(props) => props.theme.colorPrimaryYellow};
        text-decoration: underline;
      }
    }

    span:not(.color-yellow) {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      color: ${(props) => props.theme.colorTextPrimary};
      line-height: 24px;
    }
  }

  img {
    width: 95%;
    max-height: 560px;
    object-fit: cover;
    border-radius: ${globalStyles.radius.cardRadiusCorner100};

    @media (max-width: ${globalStyles.breackPoints.tablet}) {
      width: 100%;
      height: auto;
    }
  }

  h1 {
    color: ${(props) => props.theme.colorTextPrimary};
    margin: 0 0 32px 0;
  }

  ul {
    padding-left: 20px;
    line-height: 24px;
    color: ${(props) => props.theme.colorTextPrimary};
  }

  div > h2 {
    margin: 0 0 ${globalStyles.spaces[500]} 0;
  }

  .features {
    button {
      margin: ${globalStyles.spaces[400]} auto;
      display: flex;
      gap: ${globalStyles.spaces[100]};
      align-items: center;
      color: ${(props) => props.theme.colorPrimaryYellow};

      &:hover {
        background: transparent;
      }

      &.activeted {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    h4 {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      color: ${(props) => props.theme.colorTextPrimary};
      margin: 0 0 ${globalStyles.spaces[200]} 0;
    }

    .features-list {
      overflow: hidden;
      max-height: 510px;

      &.active {
        max-height: 1500px;
      }

      div {
        padding: ${globalStyles.spaces[200]} 0;
        border-bottom: 1px solid
          var(--Color-Border-Disabled, rgba(9, 30, 66, 0.06));
        display: flex;

        span:last-child {
          color: ${(props) => props.theme.colorTextPrimary};
        }
      }

      span {
        flex: 1;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: ${(props) => props.theme.colorTextSubtlest};
      }
    }
  }

  .call-container {
    gap: 24px;
    margin-top: 32px;

    button {
      padding: 12px 24px;
    }
  }

  @media (max-width: ${globalStyles.breackPoints.lgTablet}) {
    h1 {
      margin-bottom: 24px;
    }
  }

  @media (max-width: ${globalStyles.breackPoints.tablet}) {
    .call-container {
      margin-top: 24px;
      gap: 20px;
    }
  }

  @media (max-width: ${globalStyles.breackPoints.mobile}) {
    .call-container {
      flex-direction: column;
      align-items: center;

      .button {
        display: flex;
        justify-content: center;
        width: 100%;
      }

      button {
        width: 100%;
      }
    }
  }
`;

export const SimilarProjects = styled.div`
  h2 {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    color: ${(props) => props.theme.colorTextPrimary};
  }

  .see-more {
    width: 186px;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    color: #fcb026 !important;
  }

  @media (max-width: ${globalStyles.breackPoints.md}) {
    // div > a {
    //   padding: 0 16px;
    // }

    div h3 {
      font-size: 1rem;
    }

    .similar-mobile {
      div h3 {
        font-size: 14px;
        text-align: left;
      }

      img.superSmallImg {
        // max-height: 181px;
        margin: 0.5rem auto;
        padding: 0;
      }

      div p {
        margin: ${globalStyles.spaces[50]} 0;
        span {
          font-size: 14px;
        }
      }

      @media (max-width: ${globalStyles.breackPoints.tablet}) {
        div h3 {
          text-align: center;
        }
      }
    }
  }
`;
