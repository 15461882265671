/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Title } from "components/styled/Title";
import { ProjectsContainer } from "./styled";
import CarFilter from "components/parts/cars/filter";
import ListItem, { IListItem } from "components/parts/news";
import { useEffect, useState } from "react";
import { API } from "services/API/API";
import { ICategory, IModels, IPagination } from "types/category";
import { useCanonicalUrl } from "services/hooks/canonical";
import DocumentMeta from "react-document-meta";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ProjectTitle } from "consts/global";
import { FilterCotainer } from "components/parts/cars/styled";
import FilterMobile from "components/parts/cars/filterMobile";
import FilterModal from "components/parts/cars/FilterModal";
import Pagination from "components/parts/pagination";

const findElementById = (
  array: ICategory[],
  slug: string
): ICategory | null => {
  for (const element of array) {
    if (element.slug === slug) {
      return element;
    }
    if (element.children) {
      const result: ICategory | null = findElementById(element.children, slug);
      if (result) {
        return result;
      }
    }
  }
  return null;
};

const Projects = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const location = useLocation();
  const used = location.pathname === "/used";
  // let query = useQuery();
  const { id } = useParams();
  // const [type, setType] = useState("");
  const [projects, setProducts] = useState<IListItem[]>([]);
  const [category, setCategory] = useState<ICategory[]>([]);
  const [models, setModels] = useState<IModels[]>([]);
  const [filters, setFilters] = useState<number[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState<IPagination>({
    current_page: 1,
    from: 0,
    last_page: 1,
    path: "",
    per_page: 9,
    to: 0,
    total: 9,
  });

  const fetchData = (page: number) => {
    setProducts([]);
    const params: any = {};
    let models = "";

    if (id) {
      params["byCategorySlug"] = id;
    }

    if (page) {
      params["page"] = page;
    }

    if (filters.length) {
      models = filters.map((i) => `byModelId[]=${i}`).join("&");
    }

    API.projects
      .getProjects(used ? "used" : "new", params, models)
      .then((res) => {
        if (res) {
          setCategory(res.productsFilter?.categories || []);
          setModels(res?.productsFilter?.models || []);
          setPagination(res.productsData?.pagination);

          setProducts(
            res?.productsData.data.map((item: any) => {
              return {
                ...item,
                description: item?.category?.title,
                url: "/product/" + item.slug,
                img: item["media"]?.thumb,
              };
            })
          );
        }
      });
  };

  const url = useCanonicalUrl();
  const meta = {
    title: `${
      used
        ? t("secondaryEquipment")
        : id
          ? findElementById(category, id)?.title
          : t("categories")
    } | ${ProjectTitle}`,
    description: t("categories"),
    canonical: url,
  };

  useEffect(() => {
    fetchData(1);
    setPage(1);

    if (window) {
      window?.scrollTo(0, 0);
    }
  }, [used, lang, id, filters.length]);

  const onPageChange = (e: number) => {
    const element = document.getElementById("page-container");
    element?.scrollIntoView({ behavior: "smooth" });
    setPage(e);
    fetchData(e);
  };

  return (
    <DocumentMeta {...meta}>
      <ProjectsContainer className="container">
        <div className="mb-600 title">
          <Title>
            {used
              ? t("secondaryEquipment")
              : id
                ? findElementById(category, id)?.title
                : t("modelRange")}
          </Title>
        </div>

        <FilterMobile
          openFilter={() => setOpenModal(true)}
          total={pagination.total}
        />

        <div className="row">
          {!!category?.length && (
            <div className="col-3 hide-tablet">
              {!!category.length && <h3 className="mt-0">{t("filters")}</h3>}

              <CarFilter
                categories={category}
                models={models}
                filters={filters}
                onActive={() => fetchData(1)}
                setFilters={setFilters}
              />
            </div>
          )}

          <div className="col-9 col-md-12">
            {!!projects?.length && (
              <FilterCotainer className="display-flex justify-space-between align-items-center justify-space-between hide-tablet">
                <span className="hide-mobile">
                  Showing {pagination.from}–{pagination.to} of{" "}
                  {pagination.total} results
                </span>
              </FilterCotainer>
            )}

            <div className="row">
              {projects.map((item, index) => (
                <div className="col-4 col-md-6 col-sm-12" key={`news-${index}`}>
                  <ListItem projectsList {...item} description="" />
                </div>
              ))}
            </div>

            {pagination.total > pagination.per_page && (
              <Pagination
                onChange={(e) => onPageChange(e)}
                page={page}
                perPage={pagination.per_page}
                totalPage={pagination.total}
              />
            )}
          </div>
        </div>
      </ProjectsContainer>

      <FilterModal
        open={openModal}
        close={() => setOpenModal(false)}
        categories={category}
        models={models}
        filters={filters}
        setFilters={setFilters}
      />
    </DocumentMeta>
  );
};

export default Projects;
